import React, { useState } from "react"
import Layout from "components/layout"
import Seo from "components/seo"
import PageLead from "../../../components/pageLead";
import PartnerForm from "../../../components/alliance/partner/form";
import PartnerFormConfirm from "../../../components/alliance/partner/confirm";
import PartnerFormComplete from "../../../components/alliance/partner/complete";

const PartnerPage = () => {
    const [contactState, setContactState] = useState("form");
    const [formData, setFormData] = useState({
        business_form: '',
        industry: '',
        requirement: '',
        comment: '',
        company_name: '',
        last_name: '',
        first_name: '',
        last_name_ruby: '',
        first_name_ruby: '',
        email: '',
        tel: '',
        fax: ''
    });

    const handleOnNextPage = data => {
        setFormData({
            ...formData,
            business_form: data.business_form,
            industry: data.industry,
            requirement: data.requirement,
            comment: data.comment,
            company_name: data.company_name ?? "",
            last_name: data.last_name,
            first_name: data.first_name,
            last_name_ruby: data.last_name_ruby,
            first_name_ruby: data.first_name_ruby,
            email: data.email,
            tel: data.tel ?? "",
            fax: data.fax ?? "",
        });

        if (contactState === 'form'){
            setContactState('confirm');
        }else if (contactState === 'confirm'){
            setContactState('complete');
        }
    }

    const handleOnBackPage = () => {
        if (contactState === 'confirm'){
            setContactState('form');
        }
    }

    return (
        <Layout>
            <div className="partner-page">
                <PageLead sub="ALLIANCE"
                          title="ビジネスパートナー募集"
                />
                <section className={`image-wrap ${contactState === 'form' ? '' : 'hidden'}`}>
                    <img src="https://stgokulabweb.blob.core.windows.net/images/alliance/business-partner.jpg"
                         alt="Property" />
                </section>
                <section className={`contents-wrap ${contactState === 'form' ? '' : 'hidden'}`}>
                    <h2 className="title">ビジネスパートナーを<br className="sp" />募集しています</h2>
                    <p className="description">コインランドリー出店を検討されている方、既に運営中のコインランドリーの課題を相談したい方など、課題をお持ちのお客様に、<br className="pc"/>
                        広くソリューションを提供していくため、協業いただけるパートナーを募集しています。</p>
                    <h3>協業先の実績の一例</h3>
                    <p className="example-text">税理士/会計士様、金融機関様、不動産会社様など</p>
                </section>
                <PartnerForm
                    contactState={contactState}
                    handleOnNextPage={handleOnNextPage}
                />
                <PartnerFormConfirm
                    contactState={contactState}
                    handleOnNextPage={handleOnNextPage}
                    handleOnBackPage={handleOnBackPage}
                    formData={formData}
                />
                <PartnerFormComplete
                    contactState={contactState}
                />

            </div>
        </Layout>
    )
}

export const Head = () => (
    <Seo
        title="ビジネスパートナー募集"
        description="OKULAB（オクラボ）では、業務提携いただけるパートナー企業様を募集しています。"
    />
)
export default PartnerPage


