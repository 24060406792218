import React from "react"
import ReadMoreButton from "../../readMoreButton";

const PartnerFormComplete = props => {
    const { contactState } = props
    if (contactState === 'complete') {
        window.scroll({ top: 0, behavior: "auto" })
        return (
            <div className="contact-complete-wrap">
                <h3>送信が完了いたしました</h3>
                <p className="description">お問い合わせいただき、ありがとうございました</p>
                <ReadMoreButton href="/" title="トップページ" />
            </div>
        )
    }
    return ""
}

export default PartnerFormComplete
