import React from "react";
import ReadMoreButton from "./readMoreButton";

const PageLead = props => {
    function formatDescription(description) {
        // (PC限定)文字列を改行コードで分割
        return description.split('\\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br className="pc" />
            </React.Fragment>
        ));
    }
    return (
        <div className="lead-wrap">
            <div className="left-wrap">
                <div className="text-wrap">
                    <div className="sub">{props.sub}</div>
                    <h1 className="title">{props.title}</h1>
                    {props.description && (
                        <div className="description">{formatDescription(props.description)}</div>
                    )}
                    {props.linkUrl && (
                        <ReadMoreButton href={props.linkUrl} title="お問い合わせ" />
                    )}
                </div>
            </div>
            <div className="image-wrap">
                {props.imageUrl && (
                    <img src={props.imageUrl}
                         alt="Lead Image" />
                )}
            </div>
        </div>
    )
}
export default PageLead
