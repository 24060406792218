import React, { useState } from "react"
import { useForm } from "react-hook-form"

const PartnerForm = props => {
    const [ agreementState, setAgreementState] = useState(false)
    const { contactState, handleOnNextPage } = props
    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();

    const handleOnAgreementChecked = () => {
        setAgreementState(!agreementState);
    }

    const onSubmit = data => {
        handleOnNextPage(data);
    }

    if (contactState === 'form') {
        return (
            <section className="form-wrap">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="section-title">ご応募内容</div>
                    <div className="column">
                        <div className="name">
                            事業の形態<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio" id="type_personal"
                                   name="business_form"
                                   value="個人"
                                   {...register("business_form",{ required: true })}
                                   />
                            <label htmlFor="type_personal" className="radio-check">個人</label>
                            <input type="radio"
                                   id="type_company"
                                   name="business_form"
                                   value="法人"
                                   {...register("business_form",{ required: true })}
                                   />
                            <label htmlFor="type_company" className="radio-check">法人</label>
                            {errors.business_form && (
                                <span className="error-message">事業の形態を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            業種<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio"
                                   id="tax_accountant"
                                   name="industry"
                                   value="税理士"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="tax_accountant" className="radio-check w-4">税理士</label>
                            <input type="radio"
                                   id="accountant"
                                   name="industry"
                                   value="会計士"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="accountant" className="radio-check w-4">会計士</label>
                            <input type="radio"
                                   id="financial_institution"
                                   name="industry"
                                   value="金融機関"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="financial_institution" className="radio-check w-4">金融機関</label>
                            <input type="radio"
                                   id="real_estate"
                                   name="industry"
                                   value="不動産会社"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="real_estate" className="radio-check">不動産会社</label>
                            <input type="radio"
                                   id="other_industry"
                                   name="industry"
                                   value="その他"
                                   {...register("industry",{ required: true })}
                                   />
                            <label htmlFor="other_industry" className="radio-check">その他</label>
                            {errors.industry && (
                                <span className="error-message">業種を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            ご要望<span className="required">必 須</span>
                        </div>
                        <div className="form-control-radio">
                            <input type="radio"
                                   id="open"
                                   name="requirement"
                                   value="コインランドリーの出店を検討している方を紹介したい"
                                   {...register("requirement",{ required: true })}
                                   />
                            <label htmlFor="open" className="radio-check">コインランドリーの出店を検討している方を紹介したい</label>
                            <input type="radio"
                                   id="issue"
                                   name="requirement"
                                   value="コインランドリーの運営に課題をお持ちのオーナーを紹介したい"
                                   {...register("requirement",{ required: true })}
                                   />
                            <label htmlFor="issue" className="radio-check">コインランドリーの運営に課題をお持ちのオーナーを紹介したい</label>
                            <input type="radio"
                                   id="tax"
                                   name="requirement"
                                   value="中小企業経営強化税制や事業再生構築補助金の活用方法について相談したい"
                                   {...register("requirement",{ required: true })}
                                   />
                            <label htmlFor="tax" className="radio-check">中小企業経営強化税制や事業再生構築補助金の活用方法について相談したい</label>
                            <input type="radio"
                                   id="other_requirement"
                                   name="requirement"
                                   value="その他アライアンスの可能性について相談したい"
                                   {...register("requirement",{ required: true })}
                                   />
                            <label htmlFor="other_requirement" className="radio-check">その他アライアンスの可能性について相談したい</label>
                            {errors.requirement && (
                                <span className="error-message">ご要望を選択してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column no-border">
                        <div className="name">
                            コメント<span className="required">必 須</span>
                            <div className="notification">※800字以下で入力してください。</div>
                        </div>
                        <div className="form-control-text">
                                        <textarea name="comment"
                                                  maxLength="800"
                                                  className={`${errors.comment ? 'error' : ''}`}
                                                  rows={16}
                                                  {...register("comment",{ required: true })}
                                                  ></textarea>
                            {errors.comment && (
                                <span className="error-message">コメントを入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="section-title">ご依頼者様情報</div>
                    <div className="column">
                        <div className="name">
                            会社名
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="company_name"
                                   {...register("company_name")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            ご担当者様（漢字）<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="last_name"
                                   placeholder="姓"
                                   className={`${errors.last_name ? 'error' : ''} text-half`}
                                   {...register("last_name",{ required: true })}
                                   />
                            <input type="text"
                                   name="first_name"
                                   placeholder="名"
                                   className={`${errors.first_name ? 'error' : ''} text-half`}
                                   {...register("first_name",{ required: true })}
                                   />
                            {(errors.last_name || errors.first_name) && (
                                <span className="error-message">ご担当者様名を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            ご担当者様（カナ）<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="text"
                                   name="last_name_ruby"
                                   placeholder="セイ"
                                   className={`${errors.last_name_ruby ? 'error' : ''} text-half`}
                                   {...register("last_name_ruby",{ required: true })}
                                   />
                            <input type="text"
                                   name="first_name_ruby"
                                   placeholder="メイ"
                                   className={`${errors.first_name_ruby ? 'error' : ''} text-half`}
                                   {...register("first_name_ruby",{ required: true })}
                                   />
                            {(errors.last_name_ruby || errors.first_name_ruby) && (
                                <span className="error-message">ご担当者様名（カナ）を入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            メールアドレス<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="email"
                                   name="email"
                                   className={`${errors.email ? 'error' : ''}`}
                                   {...register("email",{ required: true })}/>
                            {errors.email && (
                                <span className="error-message">メールアドレスを入力してください。</span>
                            )}
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            メールアドレス（確認）<span className="required">必 須</span>
                        </div>
                        <div className="form-control-text">
                            <input type="email"
                                   name="email_confirm"
                                   className={`${errors.email_confirm ? 'error' : ''}`}
                                   {...register("email_confirm", {
                                       validate: value => {
                                           return (
                                               value === getValues("email") ||
                                               "メールアドレスが一致しません"
                                           )
                                       },
                                   })}
                            />
                            {errors.email_confirm &&
                                errors.email_confirm.type === "validate" && (
                                    <span className="error-message">{errors.email_confirm.message}</span>
                                )
                            }
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            電話番号
                        </div>
                        <div className="form-control-text">
                            <input type="tel"
                                   name="tel"
                                   {...register("tel")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            FAX
                        </div>
                        <div className="form-control-text">
                            <input type="tel"
                                   name="fax"
                                   {...register("fax")}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="name">
                            個人情報の取り扱いへの同意確認
                        </div>
                        <div className="form-control-check">
                            <div>
                                <a href="/policy" target="_blank" className="link-text">
                                    個人情報のお取り扱いに関する同意事項
                                    <img
                                        src="https://stgokulabweb.blob.core.windows.net/images/alliance/icon-open-link.svg"
                                        alt="Open Link"/>
                                </a>
                            </div>
                            <input type="checkbox"
                                   id="is_agreement_policy"
                                   name="is_agreement_policy"
                                   checked={agreementState}
                                   onChange={() => handleOnAgreementChecked()}
                                   required/>
                            <label htmlFor="is_agreement_policy"
                                   className="checkbox">個人情報のお取り扱いに関する同意事項を読み、内容に同意します。</label>
                        </div>
                    </div>
                    <div className="button-wrap">
                        <button type="submit" disabled={!agreementState}>
                            送信確認
                            <span className="bracket" />
                        </button>
                    </div>
                </form>
            </section>
        )
    }
}

export default PartnerForm
